@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,
body {
  height: 100%;
  margin: 0;
}

body {
  font-family: "Poppins", sans-serif;
}

.innerPageBody {
  padding: 30px;
}

.Bg_Pattern {
  background: url(../../assets/imgs/mainBg.png);
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  top: 0;
}

.GreenPattren {
  background: #31a347 url(../../assets/imgs/Pattern.svg);
  position: relative;
}

.MainContainer {
  background: #fff;
  max-width: 1170px;
  margin: 0 auto;
  box-shadow: 0px 0px 9px 0px rgb(173 173 173 / 74%);
  position: relative;
  min-height: 100vh;
  z-index: 0;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Poppins", sans-serif !important;
  margin: 0;
}

p {
  font-family: "Poppins", sans-serif !important;
  margin: 0;
  letter-spacing: 0 !important;
}

.sectoinTitle {
  font-size: 40px;
  color: #0b132b;
  line-height: 46px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 20px;
}

.TextalignCenter {
  text-align: center;
}

.titleBorder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.titleBorder .borderLeft {
  border-bottom: #ececec 1px solid;
  margin-right: 10%;
  flex: 1;
}

.titleBorder .borderRight {
  border-bottom: #ececec 1px solid;
  margin-left: 10%;
  flex: 1;
}

.sectionTitle {
  font-size: 40px;
  line-height: 46px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 20px;
}

.textWhite {
  color: #fff !important;
}

.textDark {
  color: #0b132b !important;
}

.subtitle {
  font-size: 20px;
  line-height: 28px;
  color: #676c7b;
  font-weight: 400;
  max-width: 920px;
  margin: 0 auto;
}

.ButtonFIlledGreen {
  background: #56b149 !important;
  text-align: center !important;
  padding: 0px 30px !important;
  border: none !important;
  margin-left: 8px !important;
  box-shadow: none !important;
  height: 50px !important;
}

.ButtonFIlledGreen .MuiButton-label {
  color: #fff !important;
  font-size: 14px;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  letter-spacing: 0.56px !important;
  font-family: "Poppins", sans-serif !important;
}

.SectionContainer {
  padding: 30px;
  position: relative;
}

.SectionGrey {
  background: #fcfcfc;
}

/* Hajj GUIDES CSS START */

.HajjGuidContainer {
  background: #eef6f1;
}

.HajjGuidContainer .HajjGuidContainerLinks {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #56b149;
  border-radius: 4px;
  opacity: 1;
  color: #56b149;
  width: 100%;
  padding: 15px 16px;
  font-size: 14px;
  text-align: left;
  justify-content: left;
}

.HajjGuidContainer .HajjGuidContainerLinks:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #56b149;
  border-radius: 4px;
  opacity: 1;
  color: #56b149;
  width: 100%;
  padding: 15px 16px;
  font-size: 14px;
  text-align: left;
  justify-content: left;
}

.HajjGuidContainer .HajjGuidContainerLinks .MuiSvgIcon-root {
  position: absolute;
  right: 15px;
  font-size: 18px;
}

@media (max-width: 1150px) {
  .HajjGuidContainer .MuiGrid-grid-xs-4 {
    max-width: 50%;
    flex-basis: 50%;
  }
}

@media (max-width: 768px) {
  .HajjGuidContainer .MuiGrid-grid-xs-4 {
    max-width: 100%;
    flex-basis: 100%;
  }
}

/* Hajj GUIDES CSS END */

/* Common services for all packages CSS START */

.CommonServicesContainer {
  background: #fff;
  padding-bottom: 95px;
}

.CommonServicesContainerInner {
  padding-bottom: 0;
}

.CommonServicesContainer .CommonServicesTitle {
  font-size: 46px;
  color: #fff;
  line-height: 56px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
}

.CommonServicesContainer .CommonServicesListing {
  display: flex;
  align-items: flex-start;
}

.CommonServicesContainer .CommonServicesListing p {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  letter-spacing: 0;
}

.CommonServicesContainer .CommonServicesListing span {
  font-weight: 600;
}

.CommonServicesContainer .CommonServicesListing svg {
  color: #fff;
  font-size: 20px;
  margin-right: 10px;
  margin-top: 5px;
}

.whiteCardContainer {
  background: #fff;
  border-radius: 4px;
  width: 100%;
  position: relative;
  bottom: -59px;
  box-shadow: 0px 0px 9px 0px rgb(173 173 173 / 74%);
  padding: 15px 10px;
  max-width: 980px;
  margin: 0 auto;
  display: flex;
}

.whiteCardContainer .whiteCard {
  text-align: center;
  min-height: 110px;
  border-right: #e4e8ec 1px solid;
  padding: 0 20px;
}

.whiteCardContainer .whiteCard:last-child {
  border: none;
}

.whiteCardContainer .whiteCard h2 {
  color: #ff6464;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
}

.whiteCardContainer .whiteCard p {
  color: #0b132b;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  margin-top: 8px;
  line-height: 24px;
  letter-spacing: 0;
}

@media (max-width: 600px) {
  .whiteCardContainer {
    display: block;
    padding: 0 30px;
    margin-top: 20px;
  }

  .whiteCardContainer .whiteCard {
    text-align: center;
    min-height: auto;
    border-right: none;
    border-bottom: #e4e8ec 1px solid;
    padding: 20px 0;
  }

  .whiteCardContainer {
    bottom: 0;
  }

  .CommonServicesContainer {
    background: #fff;
    padding-bottom: 0;
  }

  .CommonServicesContainerInner {
    padding-bottom: 30px;
  }
}

/* UMRAH PACKAGES CSS START */

.UmrahPackageContainer {
  background: #fff;
}

.UmrahPackageContainer .contactInfoContainer {
  display: flex;
  max-width: 750px;
  margin: 0 auto;
  padding-top: 14px;
}

.UmrahPackageContainer .contactInfoContainer .contactInfoBox {
  background: #fcfcfc;
  padding: 25px;
  flex: 1;
  text-align: center;
  margin: 15px;
}

.UmrahPackageContainer .contactInfoContainer .contactInfoBox svg {
  color: #56b149;
  font-size: 33px;
}

.UmrahPackageContainer
  .contactInfoContainer
  .contactInfoBox
  .contactInfoBoxTitle {
  text-transform: uppercase;
  font-size: 16px;
  color: #676c7b;
}

.UmrahPackageContainer
  .contactInfoContainer
  .contactInfoBox
  .contactInfoBoxSubTitle {
  font-size: 20px;
  color: #0b132b;
  margin-top: 8px;
}

.UmrahPackageContainer
  .contactInfoContainer
  .contactInfoBox
  .contactInfoBoxSubLink
  a {
  font-size: 14px;
  color: #56b149;
  margin-top: 12px;
}

@media (max-width: 768px) {
  .UmrahPackageContainer .contactInfoContainer {
    display: block;
  }
}

/* UMRAH PACKAGES CSS START */

.RequirementsContainer p {
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-top: 16px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

.RequirementsContainer .RequirementsList li {
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-top: 16px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  align-items: baseline;
  margin-bottom: 12px;
  letter-spacing: 0;
  line-height: 28px;
}

.RequirementsContainer .RequirementsList li svg {
  color: #ffffff;
  font-size: 14px;
  margin-right: 18px;
}

.masjidImg {
  background: url(../imgs/MasjidImg.png) no-repeat;
  position: absolute;
  bottom: 0px;
  background-size: 100%;
  width: 290px;
  height: 90px;
  left: 70px;
}

@media (max-width: 850px) {
  .masjidImg {
    position: relative;
    bottom: -30px;
  }
}

@media (max-width: 450px) {
  .masjidImg {
    left: 0px;
    margin: 0 auto;
    width: 250px;
    bottom: -42px;
  }
}

/* UMRAH PACKAGES CSS END */

/* SUBSCRIBE CSS START */

.subsCribeContainer {
  background: #eef6f1;
}

.subsCribeContainer .subsCribeTitle {
  font-size: 40px;
  color: #56b149;
  font-weight: 700;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 24px;
  max-width: 634px;
  line-height: 46px;
  letter-spacing: 0;
}

.subsCribeContainer .subsCribeForm {
  display: flex;
  align-items: center;
  max-width: 540px;
  margin: 0 auto;
  justify-content: center;
}

.subsCribeContainer .subsCribeForm .MuiFormControl-root {
  width: 59%;
}

.subsCribeContainer
  .subsCribeForm
  .MuiFormControl-root
  .MuiOutlinedInput-input {
  background: #fff;
}

.subsCribeContainer .subsCribeForm .MuiOutlinedInput-input {
  padding: 16.5px 14px !important;
}

/* SUBSCRIBE CSS END */

/* UMRAH SERVICES CSS START */
.UmrahServicesContainer {
  text-align: center;
}

.ZamzamLogoSmaill {
  width: 124px;
}

.UmrahServicesContainer .UmrahServicesTitle {
  font-size: 30px;
  color: #0b132b;
  line-height: 36px;
  margin-top: 16px;
}

.UmrahServicesContainer .UmrahServicesAddress {
  max-width: 432px;
  margin: 0 auto;
  padding-top: 8px;
  color: #676c7b;
  margin-bottom: 15px;
}

.SocialIcon a {
  display: inline-block;
  margin: 0px 5px;
}

.SocialIcon a img {
  width: 34px;
}

.WhatsAppIcon {
  color: #128c7e;
  font-size: 26px !important;
}

/* UMRAH SERVICES CSS END */

/* UMRAH SponsorQuran CSS START */

.SponsorQuranContainer {
  display: flex;
}

.SponsorQuranContainer .SponsorQuranLeftCol {
  background: #4b0082;
  text-align: center;
  width: 50%;
}

.SponsorQuranContainer .SponsorQuranLeftCol h2 {
  color: #fff;
  font-size: 40px;
  margin-bottom: 16px;
  letter-spacing: 0;
  line-height: 46px;
}

.SponsorQuranContainer .SponsorQuranLeftCol p {
  color: #fff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: 300;
}

.SponsorQuranContainer .SponsorQuranButton {
  background: #fff;
  height: 80px;
  display: flex;
  padding: 0 20px;
  border: none;
  color: #4b0082;
  font-size: 20px;
  text-transform: uppercase;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
}

.SponsorQuranContainer .SponsorQuranButton {
  background: #fff;
  height: 80px;
  display: flex;
  padding: 0 20px;
  border: none;
  color: #4b0082;
  font-size: 20px;
  text-transform: uppercase;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 8px;
}

.SponsorQuranContainer .SponsorQuranButton svg {
  color: #4b0082;
  font-size: 18px;
  margin-left: 10px;
  font-weight: 700;
}

.SponsorQuranContainer .powerdByCompanyText {
  color: #ffffff99;
  font-size: 14px;
  font-weight: 400;
}

.SponsorQuranContainer .powerdByCompanyText span {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
}

.SponsorQuranRightCol {
  text-align: center;
  width: 50%;
}

.SponsorQuranRightCol p {
  color: #676c7b;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 28px;
  letter-spacing: 0;
}

/* UMRAH SponsorQuran CSS END */

/* ABOUT US CSS START */
.AboutUstCol {
  padding: 0 30px;
  padding-top: 20px;
}

.AboutUstCol p {
  color: #676c7b;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 30px;
  letter-spacing: 0;
}

.AboutUstCol p a {
  color: #56b149;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  letter-spacing: 0;
  cursor: pointer;
}

.ZamzamTeamCol {
  padding: 0 30px;
}

.ZamzamTeamCol .sectionTitle {
  font-size: 34px !important;
}

.ZamzamTeamCard {
  text-align: center;
}

.ZamzamTeamCol .ZamzamTeamCard .ZamzamTeamCardImg {
  width: 140px;
  height: 140px;
  border-radius: 100px;
  overflow: hidden;
  margin-bottom: 16px;
  display: inline-block;
  background: #e4e8ec;
}

.ZamzamTeamCol .ZamzamTeamCard .ZamzamTeamCardImg img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.ZamzamTeamCol .ZamzamTeamCard h3 {
  font-size: 16px;
  color: #0b132b;
  line-height: 24px;
  letter-spacing: 0;
}

.ZamzamTeamCol .ZamzamTeamCard p {
  font-size: 14px;
  color: #676c7b;
  line-height: 20px;
  letter-spacing: 0;
  margin-top: 4px;
}

.aboutImg {
  background: #e4e8ec;
  overflow: hidden;
  width: 100%;
}

.aboutImg img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.aboutImg.hieght_320 {
  height: 320px;
}

.aboutImg.hieght_172 {
  height: 172px;
}

.aboutImg.hieght_198 {
  height: 198px;
}

.GroupButtonContainer {
  position: absolute;
  right: 30px;
}

.GroupButtonContainer .MuiTabs-indicator {
  display: none;
}

.GroupButtonContainer .MuiTab-root {
  color: #676c7b;
  border: 1px solid #e4e8ec;
  min-width: auto;
  padding: 0 16px;
}

.GroupButtonContainer .MuiTab-root span {
  font-size: 14px;
  text-transform: none;
  font-weight: 500;
}

.GroupButtonContainer .MuiTab-root:first-child {
  border-radius: 4px 0 0 4px;
  border-right: none;
}

.GroupButtonContainer .MuiTab-root:last-child {
  border-radius: 0px 4px 4px 0px;
  border-left: none;
}

.GroupButtonContainer .Mui-selected {
  background: #56b149;
}

.GroupButtonContainer .Mui-selected span {
  color: #fff;
}

/* ABOUT CSS END */

.InnerPageBanner {
  height: 400px;
  width: 100%;
}

.InnerPageBanner img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.InnerPageContentSection {
  margin-top: 40px;
}

.articleTitle {
  color: #1b2121 !important;
  font-size: 34px !important;
  font-weight: 700 !important;
  max-width: 635px;
  font-family: "Poppins", sans-serif !important;
}

.autherAndSocialDetails {
  display: flex;
  justify-content: space-between;
  border-bottom: #e4e8ec 1px solid;
  margin-bottom: 25px;
  margin-top: 25px;
  padding-bottom: 25px;
}

.autherAndSocialDetails .autherInfo {
  display: flex;
}

.autherAndSocialDetails .autherInfo .autherIcon {
  background: #eef6f1;
  width: 44px;
  height: 44px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #56b149;
  margin-right: 12px;
}

.autherAndSocialDetails .autherInfo h2 {
  font-size: 14px;
  font-weight: 400;
  color: #444444;
  margin: 0;
  font-family: "Poppins", sans-serif !important;
}

.autherAndSocialDetails .autherInfo p {
  font-size: 14px;
  font-weight: 400;
  color: #676c7b;
  margin: 0;
  font-family: "Poppins", sans-serif !important;
}

.autherAndSocialDetails .SocialIcon a img {
  width: 44px;
}

.ArticleContentsContainer p {
  font-size: 20px !important;
  color: #0b132b !important;
  line-height: 28px !important;
  font-weight: 400 !important;
  font-family: "Poppins", sans-serif !important;
  margin-top: 25px !important;
}

.ArticleContentsContainer .Quots {
  border-left: #005aff 2px solid;
  font-style: italic;
  padding-left: 20px;
}

.ArticleContentsContainer .articleImg {
  height: 400px;
  width: 100%;
  margin-top: 25px;
}

.ArticleContentsContainer .articleImg img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.ArticleContentsContainer h2 {
  font-size: 20px;
  color: #0b132b;
  font-weight: 700;
  line-height: 28px;
  margin-top: 25px;
}

.ArticleContentsContainer .youtubevideo {
  margin-top: 25px;
}

.ArticleContentsContainer .articleperagraph.ArtList {
  display: flex;
  align-items: baseline;
}

.ArticleContentsContainer .articleperagraph.ArtList svg {
  font-size: 12px;
  margin-right: 12px;
}

/* Article Details CSS END */

.ProSuccContainer {
  text-align: center;
  padding: 60px;
  max-width: 730px;
  margin: 0 auto;
}

.ProSuccContainer h1 {
  font-size: 34px;
  font-weight: 600;
}

.boderLine {
  background: #e4e8ec;
  height: 1px;
  width: 100%;
  margin: 20px 0;
}

.ProSuccContainer p {
  font-size: 20px;
  color: #676c7b;
  line-height: 34px;
  font-weight: 400;
  letter-spacing: 0;
}

.ProSuccContainer .TextGreen {
  color: #0b9444;
}

/* ProcessedSuccessfully CSS END */

.EventDetailsModalContainer {
  max-width: 1110px;
  margin: 0 auto;
  background: #fff;
  position: relative;
}

.EventDetailsModalContainer .ImgCol {
  position: relative;
  background: #e4e8ec;
  min-height: 576px;
  overflow: hidden;
}

.EventDetailsModalContainer .ImgCol .dtlImg {
  width: 100%;
  height: 100%;
  position: absolute;
}

.EventDetailsModalContainer .ImgCol .ShareCol {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 30px;
}

.EventDetailsModalContainer .ImgCol .ShareIcon {
  background: #fff;
  color: #444444 !important;
  font-size: 14px;
  align-items: center;
  padding: 8px 12px;
  border-radius: 4px;
  text-align: center;
  position: relative;
  margin-left: 14px;
}

.EventDetailsModalContainer .ImgCol .ShareIcon:after {
  content: " ";
  position: absolute;
  left: -7px;
  top: 10px;
  border-top: 8px solid transparent;
  border-right: 8px solid white;
  border-left: none;
  border-bottom: 8px solid transparent;
}

.EventDetailsModalContainer .ImgCol .ShareIcon svg {
  color: #444444;
  font-size: 14px;
}

.EventDetailsModalContainer .SocialIcon {
  display: flex;
  align-items: center;
}

.EventDetailsModalContainer .ImgCol .ShareCol .SocialIcon a img {
  width: 44px;
}

.DtlContentLeft {
  border-right: #e4e8ec 1px solid;
  padding: 30px;
  position: relative;
  overflow-y: auto;
}

.DtlContentLeft h2 {
  font-size: 16px;
  color: #0b132b;
  text-transform: uppercase;
  letter-spacing: 0;
}

.DtlContentLeft p {
  font-size: 16px;
  color: #676c7b;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: 8px;
}

.DtlRightContainer {
  padding: 30px;
}

.DtlRightContainer .DtlDate {
  color: #56b149;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.DtlRightContainer h1 {
  color: #0b132b;
  font-size: 34px;
  line-height: 40px;
  font-weight: 700;
  margin-top: 8px;
}

.DtlStatusCol {
  margin-top: 24px;
  margin-bottom: 30px;
}

.DtlStatusCol li {
  display: flex;
  color: #0b132b;
  font-size: 20px;
  padding: 8px 0;
  align-items: center;
}

.DtlStatusCol li svg {
  color: #676c7b;
  margin-right: 8px;
}

.SpeekerContainer h1 {
  font-size: 16px;
  color: #0b132b;
  font-weight: 600;
  text-transform: uppercase;
}

.Speekerchip {
  display: flex;
  border: #e4e8ec 1px solid;
  padding: 12px;
  align-items: center;
}

.SpeekerIcon {
  background: #eef6f1;
  width: 44px;
  height: 44px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #56b149;
  margin-right: 12px;
  overflow: hidden;
}

.SpeekerIcon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.SpeekerDetails h2 {
  font-size: 16px;
  font-weight: 700;
  color: #0b132b;
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  letter-spacing: 0;
}

.SpeekerDetails p {
  font-size: 14px;
  font-weight: 400;
  color: #4c5e67;
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  letter-spacing: 0;
  line-height: 24px;
}

.LocationContainer h1 {
  font-size: 16px;
  color: #0b132b;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0;
}

.accordionContainer {
  border: #e4e8ec 1px solid;
}

.accordionContainer {
}

.accordionContainer .MuiIconButton-label .MuiSvgIcon-root {
  color: #676c7b;
}

.accordionContainer .accordionTitle {
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.accordionContainer .accordionTitle img {
  margin-right: 13px;
}

.accordionContainer .MuiAccordionDetails-root {
  display: block;
  padding: 8px 16px 16px;
}

.accordionContainer .MuiAccordionDetails-root a {
  color: #0590fa;
  text-decoration: none;
  font-size: 16px;
  margin-bottom: 25px;
}

.accordionContainer .MuiAccordionDetails-root p {
  color: #0b132b;
  text-decoration: none;
  font-size: 16px;
}

/* accordionContainer CSS END */

.subsCribeModalContainer {
  background: #fff;
  padding: 60px;
  max-width: 660px;
  margin: 0 auto;
  text-align: center;
}

.subsCribeModalContainer h1 {
  color: #0b132b;
  font-size: 34px;
  font-weight: 700;
}

.subsCribeModalContainer p {
  color: #676c7b;
  font-size: 16px;
  font-weight: 400;
}

.subsCribeModalContainer .subsCribeForm {
  display: flex;
  max-width: 500px;
  margin: 0 auto;
  padding-top: 24px;
}

.subsCribeModalContainer .subsCribeForm .ButtonFIlledGreen {
  width: 266px;
  height: 54px !important;
}

.subsCribeModalContainer .subsCribeForm .SubscribeField .MuiOutlinedInput-root {
  position: relative;
  border-radius: 4px;
  height: 54px;
}

.subsCribeModalContainer
  .subsCribeForm
  .SubscribeField
  .MuiOutlinedInput-input {
  padding: 14.5px 14px;
  display: flex;
}

.subsCribeFormBootmText {
  font-size: 12px;
  color: #676c7b;
  margin-top: 24px;
}

.subsCribeFormBootmText a {
  color: #56b149;
  text-decoration: none;
}

.subsCribeModalContainer .SubscribeIocn {
  width: 80px;
}

.subsCribeModalContainer .CheckmarkIcons {
  margin-bottom: 38px;
}

.subsCribeModalContainer .top_space {
  margin-top: 24px;
}

.wgtInfoContainer {
  margin-top: 185px;
}

.wgtInfoContainer li {
  display: block;
  margin-bottom: 24px;
}

.wgtInfoContainer li h2 {
  color: #676c7b;
  font-size: 14px;
  display: flex;
  font-weight: 400;
  align-items: center;
}

.wgtInfoContainer li h2 svg {
  color: #d2d3d8;
  margin-right: 8px;
}

.wgtInfoContainer li p {
  color: #676c7b;
  font-size: 12px;
  font-weight: 400;
  margin-top: 8px;
}

.wgtInfoContainer li p strong {
  color: #0b132b;
}

.wgtContainer {
  max-width: 256px;
  float: right;
}

.newletterCol h2 {
  font-weight: 400;
  font-size: 16px;
  color: #0b132b;
  margin-top: 20px;
}

.newletterCol p {
  font-weight: 400;
  font-size: 14px;
  color: #676c7b;
  margin-top: 16px;
}

.newletterCol .textfield {
  margin-top: 24px;
  margin-bottom: 16px;
}

.newletterCol .inputButtonContainer .MuiButton-contained {
  background: #fff !important;
  border: #56b149 1px solid !important;
  color: #56b149 !important;
}

.newletterBottomText {
  font-weight: 400;
  font-size: 12px;
  color: #676c7b;
  margin-top: 20px;
}

.newletterBottomText a {
  color: #56b149;
}

.jAmbxV:hover:enabled,
.jAmbxV:focus:enabled {
  background-color: #56b149;
}

.CarouselContainer .rec-slider-container {
  overflow: hidden;
  position: relative;
  width: 102%;
  margin: 0 -18px;
  right: 0;
}

.CarouselContainer .rec-carousel-wrapper .rec-arrow:hover {
  background-color: #56b149 !important;
}

.WebHeaderContainer {
  position: fixed !important;
  width: 1170px;
  z-index: 99999;
  top: 0;
}

.innterContainer {
  padding-top: 122px;
}

.innterContaineCheckout {
  padding: 30px;
  padding-top: 150px;
}

@media (max-width: 1172px) {
  .WebHeaderContainer {
    position: relative !important;
    width: 100%;
  }

  .innterContainer {
    padding-top: 0;
  }
}

@media (max-width: 850px) {
  .innterContainer {
    padding-top: 0;
  }
}

@media (max-width: 768px) {
  .SponsorQuranContainer {
    display: block;
  }

  .SponsorQuranContainer .SponsorQuranLeftCol {
    width: auto;
  }

  .SponsorQuranRightCol {
    text-align: center;
    width: auto;
  }

  .SectionContainer {
    padding: 20px;
    position: relative;
  }

  .wgtContainer {
    max-width: initial;
    float: none;
  }
}

@media (max-width: 590px) {
  .subsCribeModalContainer .subsCribeForm {
    display: block;
  }

  .subsCribeModalContainer .subsCribeForm .ButtonFIlledGreen {
    width: 100%;
    height: 50px !important;
    margin-top: 15px !important;
    margin-left: 0 !important;
  }

  .subsCribeModalContainer h1 {
    line-height: 40px;
  }

  .subsCribeModalContainer
    .subsCribeForm
    .SubscribeField
    .MuiOutlinedInput-root {
    height: 54px;
  }

  .subsCribeContainer .subsCribeForm {
    display: block;
  }

  .subsCribeContainer .subsCribeForm .MuiFormControl-root {
    width: 100%;
    margin-bottom: 15px;
  }

  .ButtonFIlledGreen {
    border: none !important;
    margin-left: 0 !important;
    width: 100%;
  }

  .subsCribeContainer .subsCribeForm .MuiOutlinedInput-input {
    padding: 16.5px 16px !important;
  }

  .GroupButtonContainer {
    position: relative;
    right: 0;
  }

  .GroupButtonContainer .MuiTab-root {
    color: #676c7b;
    border: 1px solid #e4e8ec;
    min-width: auto;
    padding: 0 16px;
    width: 32%;
    margin-bottom: 20px;
  }

  .AboutUstCol {
    padding: 0 10px;
    padding-top: 20px;
  }

  .ZamzamTeamCol {
    padding: 0 10px;
  }

  .mobileView {
    padding: 0 !important;
  }

  .GalleryContainer .GalleryTitle {
    font-size: 36px;
    line-height: 41px;
  }

  .UmrahPackageContainer
    .contactInfoContainer
    .contactInfoBox
    .contactInfoBoxSubTitle {
    font-size: 15px;
    margin-top: 6px;
  }

  .autherAndSocialDetails .SocialIcon {
    margin-top: 20px;
  }

  .autherAndSocialDetails {
    display: block;
  }

  .ZamzamTeamCard {
    text-align: center;
    background: #f9f9f9;
    padding: 20px;
  }
}

.headerMenuLinks .menu-li-container a {
  font-size: 13px !important;
  color: #1b2121;
  padding: 15px 18px;
  position: relative;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #56b149 !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #56b149 !important;
}

.MuiTableCell-alignLeft svg {
  display: none;
}

.PricingTableContainer .pricingTitleBottomBody p {
  margin: 0 !important;
}

.M_TestimonialVideoImg span {
  display: none;
}

.MuiInputBase-input {
  background: #fff !important;
}

.BannerImgContainer:after {
  opacity: 0 !important;
}

.PricingTableContainer .jss1 {
  display: revert !important;
}

.contactForm .jss2 {
  border: none !important;
  padding: initial !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.contactForm .jss7 {
  right: initial !important;
  width: auto !important;
  height: auto !important;
}

.MainBanner1 .BannerImg {
  border-radius: 4px;
}

.PricingTableContainer .PricingPackageTitleContainer .Tax_text {
  position: relative;
  top: -10px;
}

.PricingTableContainer .PricingPackageTitleContainer {
  width: 440px !important;
  display: flex;
  align-items: center;
  height: 129px;
}

.PricingTableContainer .PricingPackageTitleContainer h3 {
  font-size: 40px !important;
  line-height: 49px;
  margin: 0px 0;
}

.PricingTableContainer .PricingPackageTitleContainer .Tax_text {
  display: none !important;
}

.PricingTableContainer .pricingTitleBottomBody {
  min-height: 63px !important;
  padding: 10px 30px !important;
}

.MuiTableCell-root {
  font-family: "Poppins", sans-serif !important;
}

.MuiOutlinedInput-adornedEnd {
  padding-right: 14px;
  background: #fff;
}

.RequirmentDescription a {
  font-weight: 700;
  color: #fff;
  text-decoration: none;
}

.makeStyles-greenAlertContainer-2 {
  color: #ffffff;
  height: 80px;
  line-height: 92px;
  background-color: #56b149 !important;
}

.AlertBanner {
  margin-top: 30px;
}

.AlertBanner .MuiAlert-standardSuccess {
  background-color: #ebf6ff;
}

.AlertBanner .AlertCont {
  color: #0b132b;
  font-size: 20px;
  font-weight: 400;
  font-family: "Poppins", sans-serif !important;
}

.AlertBanner .AlertCont a {
  color: #0590fa;
  font-size: 20px;
  font-weight: 400;
  font-family: "Poppins", sans-serif !important;
  text-decoration: none;
}

.AlertBanner .MuiIconButton-label .MuiSvgIcon-root {
  color: #0590fa;
}

.AlertBanner .MuiAlert-standardInfo {
  color: rgb(13, 60, 97);
  background-color: rgb(232, 244, 253);
  align-items: center;
}

.PrcTableBottomSec {
  background: #fff;
  padding: 26px;
  border: 1px solid #f1f3f5 !important;
}

.PrcTableBottomSec li {
  display: flex;
  align-items: center;
}

.PrcTableBottomSec p {
  font-family: "Poppins", sans-serif !important;
  color: #0b132b;
  font-size: 14px;
  font-weight: 400;
}

.PrcTableBottomSec p span {
  font-weight: 600;
}

.PrcTableBottomSec li svg {
  color: #e4e8ec;
  margin-right: 12px;
}

.PricingTableContainer
  .MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root:last-child {
  display: none;
}

.UpcomingEventImg {
  width: 190px;
  background-color: #e4e8ec;
  height: 247px;
}

/* ============== Registration From Start ============ */
.RgsFrmCtn {
  padding-top: 60px;
  max-width: 730px;
  margin: 0 auto;
  min-height: 400px;
  margin-bottom: 200px;
}

.RgsFrmCtn h1 {
  font-family: "Poppins", sans-serif;
  font-size: 34px;
  color: #0b132b;
  font-weight: 700;
  margin: 0;
  margin-bottom: 24px;
}

.RgsFrmCtn label + .MuiInput-formControl {
  margin: 0;
  border: #ccc 1px solid;
  padding: 10px 16px;
  border-radius: 4px;
}

.RgsFrmCtn .MuiInput-underline:before {
  display: none;
}

.RgsFrmCtn .MuiInput-underline:after {
  display: none;
}

.switchCtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: #e4e8ec 1px dashed;
  border-bottom: #e4e8ec 1px dashed;
  padding: 20px 0px;
}

.switchLabel {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #0b132b;
  font-weight: 500;
}

.InfoText {
  background: #fff;
}

.InfoText li {
  display: flex;
  align-items: flex-start;
}

.InfoText p {
  font-family: "Poppins", sans-serif !important;
  color: #0b132b;
  font-size: 14px;
  font-weight: 400;
}

.InfoText p span {
  font-weight: 600;
}

.InfoText li svg {
  color: #e4e8ec;
  margin-right: 12px;
  position: relative;
  top: 3px;
}

.AttendeeForm {
  border: #e4e8ec 1px solid;
  padding: 20px;
}

.AttendeeForm .AttendeeFormHdr {
  display: flex;
  justify-content: space-between;
}

.AttendeeForm .AttendeeFormHdr h2 {
  font-family: "Poppins", sans-serif !important;
  color: #0b132b;
  font-size: 24px;
  font-weight: 700;
}

.AttendeeForm .AttendeeFormHdr .atnEdit {
  font-family: "Poppins", sans-serif !important;
  color: #0590fa;
  font-size: 14px;
  font-weight: 500;
}

.AttendeeForm .AttendeeFormHdr .atnDelete {
  font-family: "Poppins", sans-serif !important;
  color: #0b132b;
  font-size: 14px;
  font-weight: 500;
}

.AttendeeFormDtl {
  margin-top: 15px;
}

.AttendeeFormDtl h3 {
  font-family: "Poppins", sans-serif !important;
  color: #0b132b;
  font-size: 20px;
  font-weight: 500;
}

.AttendeeFormDtl p {
  font-family: "Poppins", sans-serif !important;
  color: #676c7b;
  font-size: 16px;
  font-weight: 400;
}

/* ======= Registration From End ======= */

.UpcomingEventContents .CardReadmore svg {
  display: none !important;
}

.Boutfollowsection {
  padding-bottom: 20px;
}

.Boutfollowsection p {
  font-weight: 600 !important;
  color: #0b132b;
  margin: 5px 0;
}

.Boutfollowsection p a {
  font-weight: 600 !important;
  color: #0b132b;
  margin: 5px 0;
  text-decoration: none;
}

.input_container .MuiOutlinedInput-notchedOutline {
  width: 100% !important;
  padding: 0;
}

.contactForm .MuiOutlinedInput-notchedOutline {
  width: 100% !important;
  padding: 0;
}

.PageTitleOneTextContainer {
  position: relative;
}

.Umracard {
  border: #e4e8ec 1px solid;
}

.Umracard .UmracardContianer {
  display: flex;
}

.Umracard .UmracardContianer .UmracardImg {
  max-width: 350px;
  max-height: 196px;
  overflow: hidden;
}

.Umracard .UmracardContianer .UmracardImg img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.Umracard .UmracardContianer .UmracardDetails {
  padding: 24px;
}

.Umracard .UmracardContianer .UmracardDetails h1 {
  margin-top: 0;
  font-size: 24px;
  color: #0b132b;
  font-weight: 700;
}

.Umracard .UmracardContianer .UmracardDetails h2 {
  margin: 0;
  font-size: 16px;
  color: #56b149;
  font-weight: 700;
  margin-top: 16px;
}

.Umracard .UmracardContianer .UmracardDetails h3 {
  margin: 0;
  font-size: 14px;
  color: #676c7b;
  font-weight: 400;
}

.Umracard .UmracardContianer .UmracardDetails .MuiAccordionSummary-root {
  padding: 0 !important;
  display: inline-flex !important;
}

.Umracard .UmracardContianer .UmracardDetails .MuiAccordionSummary-root p {
  font-size: 14px;
  color: #56b149;
  font-weight: 600;
}

.Umracard .UmracardContianer .UmracardDetails .MuiAccordionSummary-root svg {
  font-size: 22px;
  color: #56b149;
}

.Umracard
  .UmracardContianer
  .UmracardDetails
  .MuiAccordionSummary-root.Mui-expanded {
  border-bottom: none !important;
}

.Umracard .MuiAccordionDetails-root {
  display: block;
  padding: 8px 16px 16px;
}

.UmrahPackageList {
  display: flex;
  margin-bottom: 16px;
}

.UmrahPackageList span {
  color: #0b132b;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.UmrahPackageList strong {
}

.UmrahPackageList svg {
  font-size: 22px;
  color: #56b149;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.UmrahPackageList h2 {
  color: #0b132b;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 4px;
}
.UmrahPackageCardContaner {
  margin-top: 30px;
}

.UmrahPackageCard {
  border: #e4e8ec 1px solid;
  padding: 16px;
  border-radius: 4px;
}

.UmrahPackageCard h1 {
  color: #0b132b;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.UmrahPackageCard h2 {
  color: #0b132b;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}

.UmrahPackageCard h2 span {
  color: #676c7b;
  font-size: 12px;
  font-weight: 400;
  position: relative;
  top: 0px;
}

.UmrahPackageCard li {
  color: #676c7b;
  font-size: 14px;
  font-weight: 400;
  margin-top: 4px;
}

.UmrahPackageCard li svg {
  color: #676c7b;
  font-size: 10px;
  position: relative;
  margin-right: 4px;
}

.UmrahPackageCard .CarBtn {
  border-radius: 100px;
  width: 100%;
  justify-content: space-between;
  color: #ffffff;
  padding: 0px 24px;
  height: 40px;
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500;
}

.UmrahPackageCard .CarBtn svg {
  font-size: 14px;
  color: #ffffff;
}

.UmrahPackageCard .CdrListing {
  min-height: 70px;
}

.CrdBlue h1 {
  color: #0590fa;
}

.CrdBlue .CarBtn {
  background: #0590fa;
}
.CrdBlue .CarBtn:hover {
  background: #0590fa;
}

.CrdGreen h1 {
  color: #06d02f;
}

.CrdGreen .CarBtn {
  background: #06d02f;
}
.CrdGreen .CarBtn:hover {
  background: #06d02f;
}

.CrdPurple h1 {
  color: #6200ee;
}

.CrdPurple .CarBtn {
  background: #6200ee;
}
.CrdPurple .CarBtn:hover {
  background: #6200ee;
}

.HajjRegistration {
  display: flex;
  background: #005aff;
  padding: 25px;
  justify-content: space-between;
  align-items: center;
}

.HajjRegistration h2 {
  margin: 0;
  color: #fff;
}

.HajjRegistration a {
  margin: 0;
  color: #fff;
  display: inline-block;
  background: #fff;
  color: #005aff;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 4px;
  font-weight: 600;
  min-width: 150px;
  text-align: center;
}

@media (max-width: 959px) {
  .Umracard .UmracardContianer {
    display: block;
  }

  .Umracard .UmracardContianer .UmracardImg {
    max-width: initial;
    max-height: 250px;
    overflow: hidden;
  }
}

@media (max-width: 468px) {
  .Umracard .UmracardContianer .UmracardImg {
    max-height: initial;
  }

  .HajjRegistration {
    display: block;
  }

  .HajjRegistration a {
    width: 90%;
    margin-top: 20px;
  }
}

/*  ---------------- */

.umragImgContainer img {
  width: 100%;
  object-fit: cover;
}
